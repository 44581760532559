<template>
  <div class="col-12 col-lg-6 c g">
    <div class="card card-body">
        <div v-if="step == 1">
            <p>اختر الفصل</p>
            <div class="row">
                <div class="col-12 col-lg-6" style="padding: 5px" v-for="c in classes" :key="c.classname + c.classroom" @click="step = 2; selected_classroom = c.classname + ',' + c.classroom">
                    <div class="classroom">
                        {{ c.classname }} - {{ c.classroom }}
                    </div>
                </div>
            </div>
        </div>
        <div v-if="step == 2">
            <p>اختر الحصة</p>
            <div class="row">
                <div class="col-12 col-lg-6" style="padding: 5px" v-for="ca in lessons" :key="ca" @click="step = 3; lesson = ca; getStudents()">
                    <div class="classroom">
                        الحصة {{ ca }}
                    </div>
                </div>
            </div>
        </div>
        <div v-if="step == 3">
            <p>
                <i class="fa fa-check-square-o"></i>
                اضغط على مرة او مرتين على حالة الطالب لتغييرها.
            </p>
            <div class="row">
                <div class="col-12 g">
                    <div class="form-group">
                      <label for="">تغيير الكل الى</label>
                      <select class="form-control" v-model="allstatus" @change="selectAll()">
                        <option value="">-- اختر --</option>
                        <option value="1">حاضر</option>
                        <option value="3">غائب</option>
                        <option value="2">متأخر</option>
                      </select>
                    </div>
                </div>
                <div class="col-12 table-responsive">
                    <table class="table table-hover table-sm">
                        <thead>
                            <th>
                                الحالة
                            </th>
                            <th>
                                الطالب
                            </th>
                        </thead>
                        <tbody>
                            <tr v-for="student in students" :key="student._id">
                                <td>
                                    <button class="btn btn-sm btn-success" style="border-radius: 0px" v-if="selected_students[student.number] == '1'" 
                                    @click="selected_students[student.number] = '3'; refresh()">
                                        <i class="fa fa-check-square"></i>
                                        حاضر
                                    </button>
                                    <button class="btn btn-sm btn-warning" style="border-radius: 0px" v-if="selected_students[student.number] == '2'" 
                                    @click="selected_students[student.number] = '1'; refresh()">
                                        <i class="fa fa-clock-o"></i>
                                        متأخر
                                    </button>
                                    <button class="btn btn-sm btn-danger" style="border-radius: 0px" v-if="selected_students[student.number] == '3'" 
                                    @click="selected_students[student.number] = '2'; refresh()">
                                        <i class="fa fa-user-times"></i>
                                        غائب
                                    </button>
                                </td>
                                <td>
                                    {{ student.name }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="col-12 text-center g">
                <button class="btn btn-primary" @click="done()">
                    <i class="fa fa-check"></i>
                    حفظ الغياب
                </button>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import { BFormCheckbox } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
export default {
    components: {
        BFormCheckbox,
    },
    directives: {
        Ripple,
    },
    data(){
        return {
            teacher: JSON.parse(localStorage.getItem('teacher')),
            students: [],
            classes: [],
            step: 1,
            selected_classroom: '',
            allstatus: "",
            lesson: '',
            lessons: [
                1,2,3,4,5,6,7,8,9,10
            ],
            selected_students: {}
        }
    },
    created(){
        var g = this;
        $.post(api + '/teacher/classes', {
            username: this.teacher.username,
            password: this.teacher.password
        }).then(function(r){
            g.loading = false
            if(r.status != 100){
                alert(r.response)
            }else{
                g.classes = r.response;
            }
        }).fail(function(){
        })
    },
    methods: {
        getStudents(){
            var g = this;
            g.students = [];
            $.post(api + '/teacher/check-attend-lesson', {
                username: this.teacher.username,
                password: this.teacher.password,
                classroom: this.selected_classroom,
                lesson: this.lesson
            }).then(function(r){
                if(r.status != 100){
                    if(r.response == "تم اخذ غياب هذه الحصة مسبقاً."){
                        var x_ = Math.random()?.toString().replace(".", "")
                        alert(`تم اخذ غياب هذه الحصة مسبقاً.<br><br>هل تريد حذف التحضير السابق لهذه الحصة والتحضير من جديد؟ <a href='javascript:;' class='f${x_} text-white'><u>اضغط هنا</u></a>`, 200)
                        setTimeout(() => {
                            $(`.f${x_}`).click(function(){
                                if(confirm(`متأكد من حذف تحضير الحصة ${g.lesson} للفصل ${g.selected_classroom}؟`)){
                                    $.post(api + '/teacher/remove-attend-lesson', {
                                        username: g.teacher.username,
                                        password: g.teacher.password,
                                        classroom: g.selected_classroom,
                                        lesson: g.lesson
                                    }).then(function(r){
                                        if(r.status == 100){
                                            alert("تم حذف تحضير الحصة بنجاح", 100)
                                        }else{
                                            alert(r.response, 200)
                                        }
                                    }).fail(function(){
                                        alert("حدث خطأ في الاتصال", 200)
                                    })
                                }
                            })
                        }, 500);
                    }
                    g.step = 2;
                }else{
                    $.post(api + '/teacher/students-by-classroom', {
                        username: g.teacher.username,
                        password: g.teacher.password,
                        classroom: g.selected_classroom
                    }).then(function(r){
                        if(r.status != 100){
                            alert(r.response, 200)
                            g.step = 1;
                        }else{
                            g.students = r.response
                            g.students.forEach(function(a){
                                g.selected_students[a.number] = '1';
                            })
                        }
                    }).fail(function(){
                        g.step = 1;
                    })
                }
            }).fail(function(){
                g.step = 1;
            })
        },
        refresh(){ this.selected_students = JSON.parse(JSON.stringify(this.selected_students)) },
        done(){
            var g = this;
            $.post(api + '/teacher/attend-lesson', {
                username: this.teacher.username,
                password: this.teacher.password,
                classroom: this.selected_classroom,
                lesson: this.lesson,
                students: JSON.stringify(g.selected_students),
                v2: true
            }).then(function(r){
                if(r.status != 100){
                    alert(r.response, 200)
                }else{
                    alert('تم بنجاح', 100)
                    g.step = 1;
                    g.selected_students = {};
                }   
            }).fail(function(){
                alert("حدث خطأ")
            })
        },
        selectAll(){
            var g = this;
            g.students.forEach(function(a){
                g.selected_students[a.number] = g.allstatus;
            })
            g.refresh()
        }
    }
}
</script>

<style>
.classroom{
    padding: 15px; 
    border: 2px solid #ddd;
    border-radius: 5px;
}
.classroom:hover{
    background: #fafafa !important;
    cursor: pointer;
    border: 2px solid #333;
}
</style>